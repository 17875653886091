import { Component } from 'react';
import Head from 'next/head';
import cookies from 'next-cookies';

// components
import ALink from 'components/ALink';

// services
import AuthService from 'services/AuthService';

// config
import { general } from 'classes/Config';

class UnauthLayout extends Component {
  static async getInitialProps() {
    const auth = new AuthService(cookies(ctx));
  }

  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;

    return (
      <>
        <Head>
          <title>{`${props.title} – ${general.appName}`}</title>
        </Head>
        <div className="container py-4 mx-auto lg:py-10">
          <div className="mx-4 xxl:w-7/12 xl:w-9/12 lg:w-11/12 md:mx-6 lg:mx-auto">
            <div className="flex flex-col bg-white dark:bg-gray-900 rounded shadow-lg md:flex-row dark:border dark:border-gray-700">
              <div className="order-1 md:order-2 md:w-6/12">
                <form
                  className="p-10 lg:p-16"
                  onSubmit={props.action}
                  autoComplete="off">
                  <h1 className="text-3xl font-bold text-primary-500">
                    {props.title}
                  </h1>
                  <p className="mt-2 text-lg font-light text-justify text-gray-600 dark:text-gray-400">
                    {props.description}
                  </p>

                  {props.children}
                </form>
              </div>
              <div className="order-2 md:order-1 rounded-t md:w-6/12 bg-primary-500 md:rounded-l md:rounded-t-none">
                <div className="p-10 text-center lg:p-16">
                  <div
                    className="w-full h-56 mb-6 bg-white bg-cover border-8 rounded-lg border-primary-100">
                    <img src="/preview.png" className="w-full h-full object-cover object-top" />
                  </div>

                  <h2 className="text-2xl font-bold text-white">
                    Synapses Dashboard
                  </h2>
                  <p className="mt-2 text-lg font-light text-white">
                    Fraud & Risk Management System
                  </p>
                  <a
                    href="https://enterprise.kredibel.co.id/"
                    className="inline-block pb-1 mt-8 text-white border-b border-white"
                    target="_blank">
                    Learn More
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-10 text-sm font-semibold tracking-wider text-center text-gray-600 uppercase">
              &copy; {(new Date()).getFullYear()} Kredibel
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UnauthLayout;
