import ComponentBase from './ComponentBase';

class FieldGroup extends ComponentBase {
	constructor(props) {
		super(props);

		this.classes = [
			'mb-5',
		];

		this.labelClasses = [
			'mb-1',
			'inline-block',
			'text-gray-600',
			'font-light'
		]
	}

	render() {
		const props = this.props;
		const classesResolve = this.classesResolve();
		const labelClasses = this.classesResolve(this.labelClasses);

		return (
			<div className={classesResolve}>
				{props.label && <label className={labelClasses}>{props.label}</label>}

				{props.children}
			</div>
		);
	}
}

export default FieldGroup;