import Link from 'next/link';

const ALink = (props) => {
	const defClassNames = [
		'text-primary-500', 
		'hover:underline'
	]

	const rmClasses = props.removeClass ?? [];

	let classNames = [
		...defClassNames.map(cl => !rmClasses.includes(cl) ? cl : ''), 
		...((props.className ?? '').split(' '))
	].join(' ').trim();

	return (
		<Link href={props.href}>
			<a className={classNames}>{props.children}</a>
		</Link>
	);
}

export default ALink;